import React, { useEffect, useState, createContext } from "react";

export const GlobalStateContext = createContext([{}, function () {}]);

const initialFormState = {
  userData: {},
  email: "",
  name: "",
  lastName: "",
  password: "",
  phone_number: "",
  authCode: "",
  formType: "",
  // Set this to true to display "account created"-message on Login form
  accountConfirmed: false,
  newsletterBool: true,
  passwordReset: false,
};

const GlobalStateProvider = ({ children }) => {
  const [user, setUser] = React.useState();
  const [cognitoUser, setCognitoUser] = React.useState();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [userAvatar, setUserAvatar] = useState({
    accessoriesType: "Blank",
    clotheColor: "PastelBlue",
    clotheType: "Hoodie",
    eyeType: "Default",
    eyebrowType: "Default",
    facialHairType: "Blank",
    hairColor: "BrownDark",
    mouthType: "Twinkle",
    skinColor: "Light",
    topType: "ShortHairTheCaesar",
  });

  const [signedIn, setSignedIn] = useState(false);
  const [formState, setFormState] = useState(initialFormState);
  const [authState, setAuthState] = React.useState(initialFormState.formType);
  const [subscriptionType, setSubscriptionType] = React.useState();
  const [subscriptionStatus, setSubscriptionStatus] = React.useState();
  const [StripeModalOpen, setStripeModalOpen] = React.useState();
  const [forcedStripeModalOpen, setForcedStripeModalOpen] = React.useState();
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [tutorial, setTutorial] = useState();
  const [trialMessage, setTrialMessage] = useState(true);
  const [isTrial, setIsTrial] = useState(false);
  const [trialStartedAt, setTrialStartedAt] = useState();
  const [trialDaysLeft, setTrialDaysLeft] = useState();
  const [snipeErrorMsg, setSnipeErrorMsg] = useState();

  const [productFinderToStart, setProductFinderToStart] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [filterSearch, setFilteredSearch] = useState(false);
  const [nbrOfSearches, setNbrOfSearches] = useState(null);
  const [noSearchResults, setNoSearchResults] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [totalSavedProductListings, setTotalSavedProductListings] = useState(0);
  const [newSavedProducts, setNewSavedProducts] = useState(0);
  const [page, setPage] = useState("account");
  // const [isTutorialJoyRideReset, setIsTutorialJoyRideReset] = useState(false);
  const [showSaveProductErrorDialog, setShowSaveProductErrorDialog] =
    useState(false);
  const [snipeRows, setSnipeRows] = useState(0);
  const [resetButtonStatus, setResetButtonStatus] = useState(false);

  useEffect(() => {
    setAuthState(formState?.formType);
    if (
      formState?.formType === "signedIn" &&
      user &&
      cognitoUser &&
      !signedIn
    ) {
      console.log("All criterias fulfilled. User Signed in!", user);
      setSignedIn(true);
    } else if (formState?.formType !== "signedIn") {
      setSignedIn(false);
    }
    const groups =
      cognitoUser?.signInUserSession?.accessToken?.payload["cognito:groups"];
    if (groups && groups.indexOf("admin") > -1) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [formState, user, cognitoUser]); //eslint-disable-line

  return (
    <GlobalStateContext.Provider
      value={{
        subscriptionStatus,
        setSubscriptionStatus,
        subscriptionType,
        setSubscriptionType,
        user,
        setUser,
        authState,
        setAuthState,
        cognitoUser,
        setCognitoUser,
        initialFormState,
        formState,
        setFormState,
        StripeModalOpen,
        setStripeModalOpen,
        forcedStripeModalOpen,
        setForcedStripeModalOpen,
        signedIn,
        filterSearch,
        setFilteredSearch,
        tutorial: tutorial,
        setTutorial,
        trialMessage,
        setTrialMessage,
        errorMsg,
        setErrorMsg,
        isTrial,
        setIsTrial,
        isAdmin,
        setIsAdmin,
        trialStartedAt,
        setTrialStartedAt,
        trialDaysLeft,
        setTrialDaysLeft,
        productFinderToStart,
        setProductFinderToStart,
        minimizedSidebar,
        setMinimizedSidebar,
        feedbackOpen,
        setFeedbackOpen,
        nbrOfSearches,
        setNbrOfSearches,
        totalSavedProductListings,
        setTotalSavedProductListings,
        newSavedProducts,
        setNewSavedProducts,
        noSearchResults,
        setNoSearchResults,
        userAvatar,
        setUserAvatar,
        name,
        setName,
        lastName,
        setLastName,
        searchButtonDisabled,
        setSearchButtonDisabled,
        // isTutorialJoyRideReset,
        // setIsTutorialJoyRideReset,
        page,
        setPage,
        showSaveProductErrorDialog,
        setShowSaveProductErrorDialog,
        snipeRows,
        setSnipeRows,
        snipeErrorMsg,
        setSnipeErrorMsg,
        phone_number,
        setPhoneNumber,
        resetButtonStatus,
        setResetButtonStatus,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
