import "./polyfill";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import { icons } from "./assets/icons";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import App from "./App";
import awsmobile from "./aws-exports";
import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloLink } from "apollo-link";
import { createAuthLink } from "aws-appsync-auth-link";
import { createHttpLink } from "apollo-link-http";
import { Auth } from "aws-amplify";

Amplify.configure(awsmobile);
Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: "revam-bucket", //REQUIRED -  Amazon S3 bucket name
      region: "eu-west-1", //OPTIONAL -  Amazon service region
    },
  },
});
window.LOG_LEVEL = "DEBUG";
// const defaultOptions = {
//   watchQuery: {
//     fetchPolicy: "network-only",
//     errorPolicy: "ignore",
//   },
//   query: {
//     fetchPolicy: "network-only",
//     errorPolicy: "all",
//   },
// };

// const url =
//   "https://a25rf2qi7zddxhsysqcnkwryne.appsync-api.eu-west-1.amazonaws.com/graphql";
// const region = "eu-west-1";
// const auth = {
//   type: "AMAZON_COGNITO_USER_POOLS",
//   jwtToken: async () =>
//     (await Auth.currentSession()).getIdToken().getJwtToken(),
// };
// const link = ApolloLink.from([
//   createAuthLink({ url, region, auth }),
//   createHttpLink({ uri: url }),
// ]);
// const client = new ApolloClient({
//   link,
//   cache: new InMemoryCache({
//     addTypename: false,
//   }),
//   defaultOptions: defaultOptions,

// });

const theme = createTheme({
  palette: {
    primary: {
      main: "#1c4d47",
      menuText: "#f7f7f7",
    },
    secondary: {
      main: "#faa307",
    },
  },
});

React.icons = icons;

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      {/* <ApolloProvider client={client}> */}
      <App />
      {/* </ApolloProvider> */}
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
