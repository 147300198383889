import "./scss/style.scss";

import Grid from "@material-ui/core/Grid";

import React from "react";
import GlobalStateProvider from "GlobalStateProvider";
import "./scss/global.css";
import "./config.js";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const LoginPage = React.lazy(() => import("./views/Login/LoginPage.jsx"));

const App = () => {
  return (
    <div>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <React.Suspense fallback={loading}>
            <GlobalStateProvider>
              <LoginPage />
            </GlobalStateProvider>
          </React.Suspense>
        </Grid>
      </Grid>
    </div>
  );
};

export default App;
